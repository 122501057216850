<template>
    <v-data-table hide-default-footer :headers="headers" :items="books">
        <template v-slot:item.action="{item}">
            <v-btn icon :to="{name:'BookDetails', params: {id: item.id}}">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </template>
        <template v-slot:item.student="{item}">
            <v-fade-transition>
                <v-btn v-if="item.student" v-show="item.student" depressed plain :to="{name:'StudentDetails', params:{id: item.student.id}}">
                    <span>{{item.student.name}}</span>
                </v-btn>
            </v-fade-transition>
        </template>
        <template v-slot:item.returnAction="{item}">
            <v-tooltip v-if="item.student" bottom>
                <template v-slot:activator="{on, attrs}">
                    <v-btn icon @click="returnBook(item.id)" v-on="on" v-bind="attrs">
                        <v-icon>mdi-arrow-down-right</v-icon>
                    </v-btn>
                </template>
                {{$t('bookGrid.returnBook')}}
            </v-tooltip>
        </template>
        <template v-slot:no-data>
            <slot name="no-data"></slot>
        </template>
    </v-data-table>
</template>
<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        }
    },
    components: {},
    data() {
         return {
             books: this.value
         }
    },
    computed: {
        headers() {
            const headers = []
            headers.push({text: this.$t('bookList.headers.title'),value:'title', width: '30%'});
            headers.push({text: this.$t('bookList.headers.isbn'), value: 'isbn', width: '25%'});
            headers.push({text: this.$t('booklist.headers.student'), value: 'student', width: '30%'})
            headers.push({text: this.$t('bookList.headers.nbTimeRented'), value: 'nbTimeRented', width: '5%'})
            headers.push({text: '', value: 'returnAction', width: '5%'})
            headers.push({text: '', value: 'action', width: '5%'});

            return headers;
        }
    },
    watch: {
        value:{
            deep:true,
            immediate:true,
            handler(v) {
                this.books = v
            }
        }
    },
    methods: {
        returnBook(bookId) {
            this.axios.delete(`/books/${bookId}`).then(() => {
                var index = this.books.findIndex(x => x.id === bookId)
                console.log(bookId)
                console.log(this.books)

                this.books[index].student = null
                
            })
        }
    }
}
</script>