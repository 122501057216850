<template>
    <v-card elevation="0">
        <v-card-title>
            <v-toolbar elevation="0" dense>
                <v-btn icon :to="{name: 'Home'}" exact>
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-tooltip bottom>
                    <template v-slot:activator="{on, attrs}">
                        <v-btn icon v-on="on" v-bind="attrs" :to="{name: 'NewBook'}">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    {{$t('studentBook.addBookButton.tooltip')}}
                </v-tooltip>
            </v-toolbar>
        </v-card-title>
        <v-card-text>
            <book-grid v-model="books">
                <template v-slot:no-data>
                    <br />
                    <h5>{{$t('bookList.noBooks')}}</h5>
                    <v-btn block class="my-4" :to="{name: 'NewBook'}">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
            </book-grid>
        </v-card-text>
    </v-card>
</template>
<script>
import BookGrid from '../components/BookList/BookGrid.vue'
export default {
    components: {BookGrid},
    data() {
         return {
             books: []
         }
    },
    computed: {},
    watch: {},
    methods: {
        loadBooks() {
            this.axios.get('/books').then(res => {
                const data = res.data.data
                this.books = data
            })
        }
    },
    created() {
        this.loadBooks()
    }
}
</script>