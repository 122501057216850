var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.books},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":{name:'BookDetails', params: {id: item.id}}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}},{key:"item.student",fn:function(ref){
var item = ref.item;
return [_c('v-fade-transition',[(item.student)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.student),expression:"item.student"}],attrs:{"depressed":"","plain":"","to":{name:'StudentDetails', params:{id: item.student.id}}}},[_c('span',[_vm._v(_vm._s(item.student.name))])]):_vm._e()],1)]}},{key:"item.returnAction",fn:function(ref){
var item = ref.item;
return [(item.student)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.returnBook(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-down-right")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('bookGrid.returnBook'))+" ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._t("no-data")]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }